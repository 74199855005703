.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: $mq-m) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.link {
  color: $white;
  font-size: 1.1rem;
  line-height: 2.6rem;
  letter-spacing: .08rem;
  text-decoration: none;

  @media (min-width: $mq-m) {
    font-size: 1.4rem;
    line-height: normal;
    letter-spacing: .1rem;
  }

  &::after {
    content: "/";
    padding: 0 2.5rem;

    @media (min-width: $mq-m) {
      padding: 0 2.5rem;
    }
  }

  &:last-child::after {
    content: none;
  }

  &:nth-child(2)::after {
    @media (max-width: $mq-m-max) {
      content: none;
    }
  }

  &:hover {
    cursor: pointer;

    span {
      text-decoration: underline;
    }
  }

  &__separator {
    width: 100%;

    @media (min-width: $mq-m) {
      display: none;
    }
  }
}
