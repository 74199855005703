@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600");
$montserrat: "Montserrat", sans-serif;

// Base
$font-size-root:          62.5%;       // html tag to make 1em equal 10px
$font-size-base:          1.6rem;      // body tag
$font-weight-base:        300;         // default weight
$font-family-base:        $montserrat; // add your custom font-face here
$border-radius-base:      .3rem;
$line-height-base:        1.4;

// Media queries
$mq-a:                    360px;
$mq-s:                    414px;
$mq-t-max:                767px;
$mq-t:                    768px;
$mq-m-max:                1023px;
$mq-m:                    1024px;
$mq-ml-max:               1279px;
$mq-ml:                   1280px;
$mq-l:                    1400px;
$mq-xl:                   1600px;

// Colors
$white: #fff;
$black: #000;
$grey:  #979797;
$text: #4b4b4b;
$primary-color: #005cb9;

// Animation
$cubic: cubic-bezier(.62, .28, .23, .99);

// Custom size
$container-m: 960px;
$container-ml: 1165px;
