body {
  margin: 0;
  padding: 0;
  color: $primary-color;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: normal;
  vertical-align: baseline;
  background: $white;
  border: 0;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}
