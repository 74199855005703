.partners {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  @media (min-width: $mq-m) {
    flex-wrap: nowrap;
    width: 58rem;
    margin-left: 11rem;
  }
}

.partner {
  display: flex;
  justify-content: center;

  &:nth-child(1),
  &:nth-child(2) {
    flex-basis: 50%;
    margin-bottom: 2rem;

    @media (min-width: $mq-m) {
      flex-basis: auto;
      margin-bottom: 0;
    }
  }
}
