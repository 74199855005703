html {
  box-sizing: border-box;
  font-size: $font-size-root;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body,
div,
span,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  border: 0;
}

input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  padding: 0;
  overflow: visible;
  color: inherit;
  font: inherit;
  line-height: normal;
  background: none;
  border: 0;
  -webkit-appearance: button; // for input
  user-select: none;
}
