.header {
  display: flex;
  justify-content: center;
  border-bottom: .1rem solid $primary-color;

  @media (min-width: $mq-m) {
    height: 20rem;
  }

  &__container {
    display: flex;
    flex-direction: column;

    @media (min-width: $mq-m) {
      flex-direction: row;
      width: $container-m;
    }
  }

  &__logo {
    position: relative;
    display: flex;
    padding: 2.5rem 0;

    @media (min-width: $mq-m) {
      width: 38rem;
      padding: 0;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: .1rem;
        height: 100%;
        background-color: $primary-color;
      }
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    @media (min-width: $mq-m) {
      align-items: center;
      margin-bottom: 0;
      margin-left: auto;
    }
  }
}
