.modal {
  &--hidden {
    display: none;
  }

  .tingle-modal__close {
    color: $primary-color;
    font-family: serif;
    font-size: 4rem;
  }

  .tingle-modal-box {
    border-radius: 0;
  }

  .tingle-modal-box__footer {
    background-color: $white;
  }

  .tingle-modal-box__content {
    h2,
    h3,
    h4,
    h5 {
      margin-bottom: 1rem;
    }

    h4,
    h5 {
      margin-top: .2rem;
    }

    p {
      margin-bottom: .8rem;
    }

    ul {
      margin-left: 2rem;
    }
  }

  .button {
    margin-left: auto;
  }

  a {
    color: $primary-color;
    text-decoration: underline;

    @media (min-width: $mq-m) {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
