@keyframes d1 {
  0%,
  5% {
    opacity: 0;
    transform: translate(-50%, calc(-50% + 5rem));
  }

  10%,
  35% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  40%,
  100% {
    opacity: 0;
    transform: translate(-50%, -50%);
  }
}

@keyframes d2 {
  0%,
  45% {
    opacity: 0;
  }

  50%,
  100% {
    opacity: 1;
  }
}

@keyframes d3 {
  0%,
  45% {
    transform: translateY(4rem);
  }

  50%,
  100% {
    transform: translateY(0)
  }
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
