.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11.5rem;
  background-color: $primary-color;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (min-width: $mq-m) {
      flex-direction: row;
      width: $container-m;
    }
  }
}
