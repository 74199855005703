.intro {
  display: flex;
  justify-content: center;
  border-bottom: .1rem solid $primary-color;

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2.5rem 2rem;

    @media (min-width: $mq-m) {
      flex-direction: row;
      width: 83rem;
      padding: 5rem 3rem 5rem 0;
      border-right: .1rem solid $primary-color;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 25.5rem;
        height: 100%;
        background-image: url(../assets/medias/test.svg);
        background-repeat: no-repeat;
        background-position: left top;
        transform: translateX(100%);
      }
    }
  }

  &__text {
    &,
    p {
      font-size: 1.8rem;
      line-height: 3.2rem;
    }

    p {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
