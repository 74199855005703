.podcast {
  display: flex;
  flex-direction: column;

  @media (min-width: $mq-m) {
    flex-direction: row;
    margin-left: 8rem;
  }

  &__player {
    width: calc(100vw - 4rem);
    height: calc(100vw - 4rem);

    @media (min-width: $mq-m) {
      flex: 0 0 auto;
      width: 46.5rem;
      height: 42rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;

    @media (min-width: $mq-m) {
      margin-top: 0;
      margin-left: 3rem;
    }
  }

  &__title {
    order: 2;
    margin-bottom: 1.5rem;
    color: $grey;
    font-size: 2.1rem;
    font-weight: 300;
    line-height: 2.6rem;

    @media (min-width: $mq-m) {
      margin-bottom: 2.2rem;
      font-size: 2.8rem;
      line-height: 3.6rem;
    }
  }

  &__subtitle {
    order: 1;
    margin-bottom: 1.2rem;
    font-size: 1.2rem;
    font-weight: 600;
    text-transform: uppercase;

    @media (min-width: $mq-m) {
    }
  }

  &__text {
    order: 3;

    &,
    p {
      color: $text;
      font-size: 1.3rem;
      line-height: 2.2rem;
    }

    p {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
