.block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: .1rem solid $primary-color;

  &__container {
    display: flex;
    flex-direction: column;
    width: calc(100vw - 4rem);
    padding: 3.5rem 0 2.5rem;

    @media(min-width: $mq-m) {
      flex-direction: row;
      width: calc(#{$container-m} - 8rem);
      margin-left: 8rem;
      padding: 8rem 0 4rem;
      border-left: .1rem solid $primary-color;
    }
  }
}
