.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 4rem);
  max-width: 36rem;

  @media (min-width: $mq-m) {
    width: 30rem;
    max-width: none;
  }

  img {
    width: 100%;
  }
}
