.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21.5rem;
  height: 5rem;
  padding: 0 3rem;
  color: $white;
  font-size: 1.2rem;
  text-decoration: none;
  background-color: $primary-color;

  @media (min-width: $mq-m) {
    width: auto;
    font-size: 1.3rem;
  }

  &:hover {
    opacity: .75;
  }
}
