.rotate {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;

  &__msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2.2rem;
    text-transform: uppercase;

    &::after {
      content: "";
      display: block;
      width: 5rem;
      height: 8.5rem;
      background-image: url("../assets/medias/rotate-mobile.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    strong {
      margin: 1rem 0 2rem;
      font-size: 2.8rem;
      font-weight: 600;
      text-transform: none;
    }
  }
}

@media only
  screen and
  (min-device-width: 320px) and
  (max-device-width: 960px) and
  (min-aspect-ratio: 15/9) {
  .app {
    display: none !important;
  }

  .rotate {
    display: flex;
  }
}
